// Action Types
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const GET_NOTIFICATIONS_USER = "GET_NOTIFICATIONS_USER";
export const GET_NOTIFICATIONS_USER_SUCCESS = "GET_NOTIFICATIONS_USER_SUCCESS";
export const GET_NOTIFICATIONS_USER_ERROR = "GET_NOTIFICATIONS_USER_ERROR";
export const SET_PAGE = "SET_PAGE";
export const GET_USER_INVOICES = "GET_USER_INVOICES";
export const GET_USER_INVOICES_SUCCESS = "GET_USER_INVOICES_SUCCESS";
export const GET_USER_INVOICES_ERROR = "GET_USER_INVOICES_ERROR";
export const UPDATE_AVATAR_USER = "UPDATE_AVATAR_USER";

// Action creator
export const getUser = () => ({
  type: GET_USER,
});

export const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  payload: {
    user,
  },
});

export const getUserError = (error) => ({
  type: GET_USER_ERROR,
  payload: {
    error,
  },
});

export const getNotificationsUser = () => ({
  type: GET_NOTIFICATIONS_USER,
});

export const getNotificationsUserSuccess = (data) => ({
  type: GET_NOTIFICATIONS_USER_SUCCESS,
  payload: {
    data,
  },
});

export const getNotificationsUserError = (error) => ({
  type: GET_NOTIFICATIONS_USER_ERROR,
  payload: {
    error,
  },
});

export const setPage = (data) => ({
  type: SET_PAGE,
  payload: {
    data,
  },
});

export const getUserInvoices = () => ({
  type: GET_USER_INVOICES,
});

export const getUserInvoicesSuccess = (data) => ({
  type: GET_USER_INVOICES_SUCCESS,
  payload: {
    data,
  },
});

export const getUserInvoicesError = (error) => ({
  type: GET_USER_INVOICES_ERROR,
  payload: {
    error,
  },
});

export const updateAvatarUser = (avatar) => ({
  type: UPDATE_AVATAR_USER,
  payload: { avatar },
});

export function getUserThunk({ isAdmin }) {
  return function (dispatch, getState, { api }) {
    const id = localStorage.getItem("user_id");
    if (id) {
      dispatch(getUser());
    } else {
      return getUserError("no_loggedin");
    }

    const getUserEndpoint = isAdmin ? api.adminGetUser : api.getLoggedUser;

    return getUserEndpoint({ id })
      .then((response) => dispatch(getUserSuccess(response.data)))
      .catch((error) => {
        console.error(error);
        dispatch(getUserError(error));
      });
  };
}

export function getNotificationsUserThunk() {
  return function (dispatch, getState, { api }) {
    dispatch(getNotificationsUser());
    const userId = getState().user.data.id;
    const { isAdmin } = getState().user.data;
    const getNotificationsUserEndpoint = isAdmin ? api.adminGetNotificationsUser : api.getNotificationsUser;
    return getNotificationsUserEndpoint({ userId })
      .then((response) => dispatch(getNotificationsUserSuccess(response.data.payload)))
      .catch((error) => {
        dispatch(getNotificationsUserError(error));
      });
  };
}

export const getUserInvoicesThunk = ({ filters, sort, page, pageSize } = {}) => {
  return function (dispatch, getState, { api }) {
    dispatch(getUserInvoices());
    const userId = getState().user.data.id;
    return api
      .getUserInvoices({ userId, filters, sort, page, pageSize })
      .then((response) => {
        dispatch(getUserInvoicesSuccess(response.data.payload.data));
      })
      .catch((error) => {
        dispatch(getUserInvoicesError(error));
      });
  };
};
