import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function AdIcon({ fill = "black", width = 50, height = 50, ...rest }) {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 100 100" fill="none" {...rest}>
      <path
        d="M68.7779 17H65.3793C64.8129 17 64.2464 17.2525 63.8107 17.7575C53.8329 29.8263 42.635 32.5027 41.2843 32.7552H21.285C17.5379 32.7552 10.1743 37.0474 10 46.9449C10 46.9954 10 47.0459 10 47.1469V47.1974V47.2479C10 47.2984 10 47.3489 10 47.4499C10.1743 57.2464 17.3636 61.5386 21.1543 61.6396L26.165 81.2326C26.4264 82.293 27.2543 83 28.2129 83H39.0186C39.7157 83 40.3257 82.6465 40.7614 81.9901C41.1536 81.3336 41.2843 80.5256 41.11 79.7682L36.3171 61.6396H41.3714C42.7221 61.8921 53.8329 64.518 63.8979 76.6373C64.29 77.1423 64.8564 77.3948 65.4664 77.3948H68.865C70.0414 77.3948 71 76.2839 71 74.9204V19.4744C70.9564 18.1109 69.9979 17 68.7779 17ZM14.3136 47.2479C14.3136 47.1974 14.3136 47.1974 14.3136 47.2479C14.3136 47.1974 14.3136 47.1469 14.3136 47.1469C14.3571 39.9763 19.76 37.7544 21.285 37.7544H39.3671V56.6909H21.285C19.76 56.6404 14.3571 54.368 14.3136 47.2479ZM36.1429 78.0008H29.825L25.6421 61.5891H31.8293L36.1429 78.0008ZM66.6429 72.3956H66.2943C57.2314 61.8416 47.6021 58.2058 43.6371 57.0949V37.2494C47.6021 36.1385 57.2314 32.5027 66.2943 21.9487H66.6429V72.3956Z"
        fill={fill}
      />
      <path
        d="M87.811 45H79.189C77.9828 45 77 45.898 77 47C77 48.102 77.9828 49 79.189 49H87.811C89.0172 49 90 48.102 90 47C90 45.898 89.0172 45 87.811 45Z"
        fill={fill}
      />
      <path
        d="M78.6004 39.9239C78.8136 39.8755 79.0695 39.7303 79.24 39.585L86.0626 34.3564C87.0433 33.6302 87.2992 32.1294 86.6169 31.0643C85.9773 29.9508 84.6554 29.6603 83.7173 30.4349L76.9374 35.6636C75.9567 36.3898 75.7008 37.8906 76.3831 38.9556C76.8521 39.8271 77.7476 40.166 78.6004 39.9239Z"
        fill={fill}
      />
      <path
        d="M86.0912 58.6608L79.2578 53.4118C79.0443 53.266 78.8307 53.1201 78.6172 53.0715C77.763 52.8285 76.8661 53.2174 76.3536 54.0436C75.713 55.1614 75.9692 56.6195 76.9088 57.3486L83.7422 62.5976C84.7245 63.3266 86.0058 63.035 86.6464 61.9658C87.287 60.8965 87.0308 59.3898 86.0912 58.6608Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
