import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import {
  GET_SPACE,
  GET_SPACE_SUCCESS,
  GET_SPACE_ERROR,
  GET_SPOTS_CATEGORIES,
  GET_SPOTS_CATEGORIES_SUCCESS,
  GET_SPOTS_CATEGORIES_ERROR,
  POST_SPACE_CATEGORIES,
  POST_SPACE_CATEGORIES_SUCCESS,
  POST_SPACE_CATEGORIES_ERROR,
  POST_SPACE,
  POST_SPACE_SUCCESS,
  POST_SPACE_ERROR,
  PUT_SPACE,
  PUT_SPACE_SUCCESS,
  PUT_SPACE_ERROR,
  PUBLISH_SPACE,
  PUBLISH_SPACE_ERROR,
  PUBLISH_SPACE_SUCCESS,
  SET_SPACE_ATTACHMENTS,
  SET_SPACE_ATTACHMENTS_ERROR,
  SET_SPACE_ATTACHMENTS_SUCCESS,
  RESET_SPACE,
  GET_SPACE_UNAVAILABLE_DATE_RANGES,
  GET_SPACE_UNAVAILABLE_DATE_RANGES_ERROR,
  GET_SPACE_UNAVAILABLE_DATE_RANGES_SUCCESS,
  UPDATE_DATA_NEW_SPACE_SUCCESS,
} from "../actions/space-actions";

import { add, dateDiff } from "../../utils/dateUtils";

export const INITIAL_STATE = {
  status: "initial",
  getStatus: "initial",
  data: {
    status: "initial",
    features: [],
    uses: [],
    title: "",
    description: "",
    full_month_price: 100,
    full_week_price: 10,
    full_weekend_price: 3,
    week_day_price: 1,
    id: 0,
    latitude: 0,
    longitude: 0,
    image: "",
    images: [],
    plans: [],
    use: {},
    type: {},
    subtype: {},
    size: 0,
    capacity: 0,
    min_stay: 1,
    min_stay_unit: "days",
    max_stay: 180,
    asset: {
      city: {},
      latitude: 0,
      longitude: 0,
    },
    tags: [],
    contractModelId: "",
    contractModelAttachment: "",
    area_unit: "m2",
    dimensions: undefined,
  },
  unavailableDateRangesStatus: "initial",
  // this will be an object to quick access later
  unavailableDates: {},
  unavailableDatesRange: [],
};

const divideAttachments = (data) => ({
  images: data?.attachments?.filter((a) => a.type === "image"),
  plans: data?.attachments?.filter((a) => a.type === "plan"),
});

export const transformAsset = (asset) => {
  return asset
    ? {
        ...asset,
        latitude: parseFloat(asset?.latitude),
        longitude: parseFloat(asset?.longitude),
        config: asset.config ? (typeof asset.config === "string" ? JSON.parse(asset.config) : asset.config) : {},
      }
    : {};
};

export const transformSpace = (data) => {
  if (data === null) {
    return {};
  }

  const { images, plans } = divideAttachments(data);

  const categories = [];

  if (data?.uses) {
    data?.uses?.map((u) => {
      if (!categories.includes(u.category)) {
        categories.push(u.category);
      }
    });
  }
  const asset = transformAsset(data?.asset);

  return {
    ...data,
    images,
    plans,
    latitude: parseFloat(data?.latitude),
    longitude: parseFloat(data?.longitude),
    uses: data?.uses || [],
    useCategories: categories,
    type: data?.type || {},
    subtype: data?.subtype || {},
    min_stay: data?.min_stay || 1,
    max_stay: data?.max_stay || 180,
    contractModelId: data?.contract_model_id || "",
    contractModelAttachment: data?.contract_model_attachment || "",
    title: data?.title,
    name: data?.title,
    assetName: data?.asset?.name,
    asset,
    city: data?.asset?.city ? data?.asset?.city : {},
    image: images?.length ? images[0]?.url : "/favicon.png",
    address: data?.asset?.address,
    display_price: asset.config?.show_space_price ? data.display_price : 0,
  };
};

const listingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SPACE:
      return {
        ...state,
        getStatus: "loading",
        data: {
          ...INITIAL_STATE.data,
          title: i18n._(t`Cargando...`),
        },
      };
    case GET_SPOTS_CATEGORIES:
      return {
        ...state,
        spacesCategoriesStatus: "loading",
      };
    case GET_SPOTS_CATEGORIES_SUCCESS:
      return {
        ...state,
        spacesCategories: Object.values(action.payload),
        spacesCategoriesStatus: "success",
      };
    case GET_SPOTS_CATEGORIES_ERROR:
      return {
        ...state,
        spacesCategoriesStatus: "error",
      };
    case POST_SPACE_CATEGORIES:
      return {
        ...state,
        status: "loading",
      };
    case POST_SPACE_CATEGORIES_SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case POST_SPACE_CATEGORIES_ERROR:
      return {
        ...state,
        status: "error",
      };
    case POST_SPACE:
      return {
        ...state,
        status: "loading",
      };
    case PUT_SPACE:
      return {
        ...state,
        status: "loading",
      };
    case GET_SPACE_ERROR:
      return {
        ...state,
        getStatus: "error",
      };
    case POST_SPACE_ERROR:
      return {
        ...state,
        status: "error",
      };
    case PUT_SPACE_ERROR:
      return {
        ...state,
        status: "error",
      };
    case GET_SPACE_SUCCESS:
      const { data } = action.payload;
      const space = transformSpace(data.payload);
      return {
        ...state,
        data: {
          ...space,
          owner: data.payload.owner_id,
          image: space.images?.length ? space.images[0]?.url : "/favicon.png",
        },
        getStatus: "success",
      };
    case POST_SPACE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
        getStatus: "success",
        status: "success",
      };
    case PUT_SPACE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
        attachStatus: "initial",
        status: "success",
      };
    case PUBLISH_SPACE: {
      return {
        ...state,
        publishStatus: "loading",
      };
    }
    case PUBLISH_SPACE_SUCCESS: {
      return {
        ...state,
        publishStatus: "success",
        data: {
          ...state.data,
          searchable: action.payload.searchable,
        },
      };
    }
    case PUBLISH_SPACE_ERROR: {
      return {
        ...state,
        publishStatus: "error",
      };
    }
    case SET_SPACE_ATTACHMENTS:
      return {
        ...state,
        attachStatus: "loading",
      };
    case SET_SPACE_ATTACHMENTS_SUCCESS: {
      const { attachments } = action.payload;

      return {
        ...state,
        attachStatus: "success",
        data: {
          ...state.data,
          ...divideAttachments({ attachments }),
        },
      };
    }
    case SET_SPACE_ATTACHMENTS_ERROR:
      return {
        ...state,
        attachStatus: "error",
      };
    case GET_SPACE_UNAVAILABLE_DATE_RANGES:
      return {
        ...state,
        unavailableDateRangesStatus: "loading",
      };
    case GET_SPACE_UNAVAILABLE_DATE_RANGES_SUCCESS: {
      const { data } = action.payload;
      /**
       * creates a hash with [date]: reservation to
       *  provide quick access to unavailable dates
       */
      const unavailableDates = data.reduce((acc, curr) => {
        const { start, end } = curr;
        const diff = dateDiff(start, end);
        for (let i = 0; i <= diff; i++) {
          const date = add(start, i, "day").format("YYYY-MM-DD");
          acc[date] = curr;
        }
        return acc;
      }, {});

      return {
        ...state,
        unavailableDateRangesStatus: "success",
        unavailableDates,
        unavailableDatesRange: data,
      };
    }
    case GET_SPACE_UNAVAILABLE_DATE_RANGES_ERROR:
      return {
        ...state,
        unavailableDateRangesStatus: "error",
      };
    case RESET_SPACE:
      return {
        ...state,
        data: {
          ...INITIAL_STATE.data,
        },
      };
    case UPDATE_DATA_NEW_SPACE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default listingReducer;
