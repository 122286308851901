import { getFeatureFlagsError, getFeatureFlagsSuccess } from "./misc-actions";

export const UPDATE_CONSULTS = "UPDATE_CONSULTS";
export const GET_CONSULTS = "GET_CONSULTS";
export const GET_CONSULTS_SUCCESS = "GET_CONSULTS_SUCCESS";
export const GET_CONSULTS_ERROR = "GET_CONSULTS_ERROR";
export const GET_MORE_CONSULTS_SUCCESS = "GET_MORE_CONSULTS_SUCCESS";

export const updateConsults = (data) => ({
  type: UPDATE_CONSULTS,
  payload: {
    data,
  },
});

export const getConsults = (status, index) => ({
  type: GET_CONSULTS,
  payload: {
    status,
    index,
  },
});

export const getConsultsSuccess = (status, index, search, data) => ({
  type: GET_CONSULTS_SUCCESS,
  payload: {
    status,
    index,
    search,
    data,
  },
});

export const getConsultsError = (status, index, search, error) => ({
  type: GET_CONSULTS_ERROR,
  payload: {
    status,
    index,
    search,
    error,
  },
});

export const getMoreConsultsSuccess = (data) => ({
  type: GET_MORE_CONSULTS_SUCCESS,
  payload: {
    data,
  },
});

export function updateConsultsThunk(data) {
  return function (dispatch, getState, { api }) {
    const updatedConsults = getState().consults.data.map((c) => {
      const updatedConsult = data.find((d) => c.id === d.id);
      if (c.id === updatedConsult?.id) {
        return updatedConsult;
      }
      return c;
    });
    dispatch(updateConsults(updatedConsults));
  };
}

// this should be used only for the tenant
export function getConsultsThunk({ status, index, pageSize = 5, search }) {
  return async function (dispatch, getState, { api }) {
    dispatch(getConsults(status, index));
    const userId = localStorage.getItem("user_id");
    let { featureFlags } = getState().misc;
    if (!Object.keys(featureFlags).length) {
      featureFlags = await api.getFeatureFlags().then((res) => {
        if (res.status === 200) {
          dispatch(getFeatureFlagsSuccess(res.data?.payload));
        } else {
          dispatch(getFeatureFlagsError(res.data?.message));
        }
        return res?.data?.payload || {};
      });
    }
    return api
      .getClientConsultations({
        status,
        userId,
        page: index,
        pageSize,
        search,
      })
      .then((response) => {
        const { data } = response;
        dispatch(getConsultsSuccess(status, index, search, data.payload));
      })
      .catch((error) => {
        dispatch(getConsultsError(status, index, search, error));
      });
  };
}

export function getMoreConsultsThunk({ status }) {
  return function (dispatch, getState, { api }) {
    const perPage = getState().consults?.perPage;
    const index = getState().consults?.currentIndex;
    dispatch(getConsults(status, index));
    const userId = localStorage.getItem("user_id");

    return api
      .getClientConsultations({
        status,
        userId,
        page: index + 1,
        pageSize: perPage,
      })
      .then((response) => {
        const { data } = response;
        dispatch(getMoreConsultsSuccess(data.payload));
      })
      .catch((error) => {
        dispatch(getConsultsError(status, index, error));
      });
  };
}
