import { createStore as reduxCreateStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

import api from "./../api";

const middleware = applyMiddleware(thunk.withExtraArgument({ api }));
// Note: this API requires redux@>=3.1.0

const createStore = () =>
  reduxCreateStore(
    rootReducer,
    typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? compose(middleware, composeWithDevTools())
      : compose(middleware),
  );

export default createStore;
