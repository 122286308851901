import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_NOTIFICATIONS_USER,
  GET_NOTIFICATIONS_USER_SUCCESS,
  GET_NOTIFICATIONS_USER_ERROR,
  SET_PAGE,
  GET_USER_INVOICES,
  GET_USER_INVOICES_SUCCESS,
  GET_USER_INVOICES_ERROR,
  UPDATE_AVATAR_USER,
} from "../actions/user-actions";
import { POST_LOGIN, POST_LOGIN_SUCCESS, POST_LOGIN_ERROR, POST_LOGOUT, URL_REDIRECT } from "../actions/auth-actions";
import { GET_BOOKING_SUCCESS } from "../actions/consultation-actions";
import { userFormatter } from "../../utils";
import { CONSULT_STATUS, getConsultationStatusFromStatus, isBookingConfirmedOrDone } from ".";

const INITIAL_STATE = {
  data: {
    id: null,
    username: "",
    name: "",
    lastname: "",
    isOwner: false,
    locale: "es",
    permissions: "r",
    managedAssets: [],
    flags: {},
    scopes: [],
    role: "",
  },
  isAuthenticated: false,
  status: "initial",
  notifications: [],
  notificationsStatus: "initial",
  notificationsError: "",
  bookingConfirmedNews: 0,
  bookingProgressNews: 0,
  bookingCancelledConfirmedNews: 0,
  bookingCancelledProgressNews: 0,
  loginStatus: "initial",
  page: "signin",
  invoices: [],
  invoicesStatus: "initial",
  urlRedirect: "",
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        status: "loading",
      };
    case POST_LOGIN:
      return {
        ...state,
        loginStatus: "loading",
      };
    case POST_LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        loginStatus: "success",
        data: {
          ...userFormatter(action.payload.data),
        },
      };
    }
    case POST_LOGIN_ERROR:
      return {
        ...state,
        loginStatus: "error",
      };
    case POST_LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    case GET_USER_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        data: {
          ...userFormatter(user.payload),
        },
        isAuthenticated: true,
        status: "success",
      };
    }
    case GET_USER_ERROR:
      return {
        ...state,
        status: "error",
      };
    case GET_NOTIFICATIONS_USER:
      return {
        ...state,
        notificationsStatus: "loading",
        notificationsError: "",
      };
    case GET_NOTIFICATIONS_USER_ERROR:
      return {
        ...state,
        notificationsError: action.payload.error,
        notificationsStatus: "error",
      };
    case GET_NOTIFICATIONS_USER_SUCCESS:
      const notifications = action.payload.data;
      const { bookingConfirmedNews, bookingProgressNews, bookingCancelledConfirmedNews, bookingCancelledProgressNews } =
        notifications.reduce(
          (acc, b) => {
            if (isBookingConfirmedOrDone(b.confirmedAt)) {
              acc.bookingConfirmedNews++;
            } else if (b.booking_status === CONSULT_STATUS.CANCELED && b.previously_paid) {
              //if paid and cancelled, also is confirmed news and cancelled confirmed
              acc.bookingConfirmedNews++;
              acc.bookingCancelledConfirmedNews++;
            } else if (CONSULT_STATUS.IN_PROGRESS === getConsultationStatusFromStatus(b.booking_status)) {
              acc.bookingProgressNews++;
            } else if (b.booking_status === CONSULT_STATUS.CANCELED && !b.previously_paid) {
              // if unpaid and cancelled, also in progress news and cancelled progress
              acc.bookingProgressNews++;
              acc.bookingCancelledProgressNews++;
            }
            return acc;
          },
          {
            bookingConfirmedNews: 0,
            bookingProgressNews: 0,
            bookingCancelledConfirmedNews: 0,
            bookingCancelledProgressNews: 0,
          },
        );

      return {
        ...state,
        notificationsStatus: "success",
        notifications,
        bookingConfirmedNews,
        bookingProgressNews,
        bookingCancelledConfirmedNews,
        bookingCancelledProgressNews,
      };
    case GET_BOOKING_SUCCESS: {
      const booking = action.payload;
      const news = {};
      switch (getConsultationStatusFromStatus(booking.status)) {
        case CONSULT_STATUS.IN_PROGRESS:
          news.bookingProgressNews = state.bookingProgressNews > 0 ? state.bookingProgressNews - 1 : 0;
          break;
        case CONSULT_STATUS.CONFIRMED:
        case CONSULT_STATUS.CANCELED:
          news.bookingConfirmedNews = state.bookingConfirmedNews > 0 ? state.bookingConfirmedNews - 1 : 0;
      }
      return {
        ...state,
        ...news,
      };
    }
    case SET_PAGE:
      return {
        ...state,
        page: action.payload.data,
      };
    case GET_USER_INVOICES:
      return {
        ...state,
        invoicesStatus: "loading",
        invoices: [],
      };
    case GET_USER_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesStatus: "success",
        invoices: action.payload.data,
      };
    case GET_USER_INVOICES_ERROR:
      return {
        ...state,
        invoicesStatus: "error",
      };
    case URL_REDIRECT:
      return {
        ...state,
        urlRedirect: action.payload,
      };
    case UPDATE_AVATAR_USER: {
      return {
        ...state,
        data: {
          ...state.data,
          avatar: action.payload.avatar,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default userReducer;
