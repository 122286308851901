import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { CssBaseline } from "@material-ui/core";
import { getThemeById } from "../theme";

export default (props) => {
  const currentAsset = useSelector((state) => state.misc.currentAsset);
  let id = 0;
  // When SSG works, this comes in the props
  if (props.assetId) {
    id = props.assetId;
  } else {
    id = currentAsset ? currentAsset.id : 0;
  }
  const theme = getThemeById(id);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};
