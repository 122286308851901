import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import minMax from "dayjs/plugin/minMax";

export const DIFFERENCE_FORMAT = {
  DAY: "d",
  WEEK: "w",
  QUARTER: "Q",
  MONTH: "M",
  YEAR: "y",
  HOUR: "h",
  MINUTE: "m",
  SECOND: "s",
  MILLISECOND: "ms",
};

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(minMax);

export const dateToDayjs = (date) => {
  return dayjs(date);
};

export const formatDate = (date, format) => {
  return dayjs(date).utc(true).format(format);
};

export const formatDateUtc = (date, format = "YYYY-MM-DD") => formatDate(date, format, false);

export const formatDateToHHmm = (utcDateString) => {
  const date = new Date(`${utcDateString}Z`);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const dateWithFormat = (date, format) => {
  return dayjs(date, format);
};

export const getYear = (date) => {
  return formatDate(date, "YYYY");
};

export const getDay = (date) => {
  return formatDate(date, "DD");
};

export const getMonth = (date) => {
  return formatDate(date, "MM");
};

export const dateDiff = (start, end, diffFormat = DIFFERENCE_FORMAT.DAY) => {
  const d1 = dayjs(start);
  const d2 = dayjs(end);
  return d2.diff(d1, diffFormat);
};

export const getFurtherDate = (date1, date2) => {
  const d1 = dayjs(date1);
  const d2 = dayjs(date2);

  if (d1.isAfter(d2)) {
    return date1;
  }
  return date2;
};

export const getMonthShortName = (date) => {
  return formatDateUtc(date, "MMM").toUpperCase().substr(0, 3);
};

export const getDays = (start, end, format = "YYYY-MM-DD") => {
  const d1 = dayjs(start, format);
  const d2 = dayjs(end, format);
  const days = Math.floor(dateDiff(d1, d2)); // the same day is 1
  return Number.isNaN(days) ? 0 : days + 1;
};

export const getReservationMonths = (start, end, format = "YYYY-MM-DD") => {
  const d1 = dayjs(start, format);
  const d2 = dayjs(end, format);
  const months = Math.floor(dateDiff(d1, d2, DIFFERENCE_FORMAT.MONTH)); // the same day is 1
  return Number.isNaN(months) ? 0 : months + 1;
};

export const getReservationWeeks = (start, end, format = "YYYY-MM-DD") => {
  const d1 = dayjs(start, format);
  const d2 = dayjs(end, format);
  const weeks = Math.floor(dateDiff(d1, d2, DIFFERENCE_FORMAT.WEEK));
  return Number.isNaN(weeks) ? 0 : weeks + 1;
};

export const isDateValid = (date) => {
  return dayjs(date).isValid();
};

export const isDateInRangeInclusive = (date, min, max) => {
  return dayjs(date).isBetween(dayjs(min), dayjs(max), "[", "]");
};

export const formatUTCDateNow = (format) => {
  return dayjs.utc().format(format);
};

export const withLocale = (date, locale = "en") => dayjs(date).locale(locale);

export const add = (date, units = 1, type = "day") => {
  return dayjs(date).add(units, type);
};

export const subtract = (date, units = 1, type = "day") => {
  return dayjs(date).subtract(units, type);
};

export const isBefore = (d1, d2) => dayjs(d1).isBefore(dayjs(d2));

export const isSameOrBeforeDate = (date, end, type = "day") => dayjs(date).isSameOrBefore(end, type);

export const dateWithoutTimezone = (date) => new Date(`${date}T00:00:00`);

// year, month, day, hour..
export const getStartOf = (unit) => dayjs().startOf(unit);
export const getEndOf = (unit) => dayjs().endOf(unit);

export const getNow = () => dayjs.utc();

export const dateFormattedStartToEnd = (filters) =>
  `${formatDateUtc(filters.start, "DD/MM/YYYY")} - ${formatDateUtc(filters.end, "DD/MM/YYYY")}`;

export const getDatesWithinDates = (range) => {
  const startOfRange = formatDateUtc(dayjs.min([dayjs(range[0]), dayjs(range[1])]));
  const endOfRange = formatDateUtc(dayjs.max([dayjs(range[0]), dayjs(range[1])]));
  const result = [];
  let currentDate = dayjs(startOfRange);
  while (currentDate.isBefore(endOfRange) || currentDate.isSame(endOfRange)) {
    result.push(currentDate.format());
    currentDate = currentDate.add(1, "day");
  }
  return result;
};

export const getTimeConstraint = (range) => {
  const fullRange = getDatesWithinDates(range);
  const result = {};
  fullRange.forEach((day, index) => {
    result[formatDateUtc(new Date(day))] = {
      id: `TIME_CONSTRAINT_${index}`,
      start: formatDateUtc(new Date(fullRange[0])),
      end: formatDateUtc(new Date(fullRange[fullRange.length - 1])),
    };
  });
  return result;
};
