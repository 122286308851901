import axios from "axios";
import config from "../config";

const BASE_URL_API = `${config.API_URL}/api/v1`;

const login = async ({ email, password }) => {
  return axios.post(`${BASE_URL_API}/login`, {
    email,
    password,
  });
};

const register = async (name, lastname, phone, email, password, passwordConfirmation, companyId) => {
  return axios.post(
    `${BASE_URL_API}/register`,
    {
      name,
      lastname,
      phone,
      email,
      password,
      password_confirmation: passwordConfirmation,
      company_id: companyId,
    },
    {
      headers: { "Content-Type": "application/json" },
    },
  );
};

const sendEmailResetPassword = async (email) => {
  return axios.post(`${BASE_URL_API}/forgot`, {
    email,
  });
};

const resetPassword = async ({ token, email, password, passwordConfirmation }) => {
  return axios.post(`${BASE_URL_API}/reset`, {
    token,
    email,
    password,
    password_confirmation: passwordConfirmation,
  });
};

const inviteCustomer = ({ name, lastname, email, phone, password, passwordConfirmation, companyId }) => {
  return axios.post(`${BASE_URL_API}/customercreate`, {
    name,
    lastname,
    email,
    phone,
    password,
    password_confirmation: passwordConfirmation,
    company_id: companyId,
  });
};

const inviteUser = ({ name, email, lastname, phone, company_id }) => {
  return axios.post(`${BASE_URL_API}/userinvitations`, { name, email, lastname, phone, company_id });
};

const resendUserInvitation = (invitationId) => {
  return axios.post(`${BASE_URL_API}/userinvitations/send/${invitationId}`, {});
};

const changePassword = async ({ currentPassword, password, passwordConfirmation }) => {
  return axios.post(`${BASE_URL_API}/changepassword`, {
    current_password: currentPassword,
    password,
    password_confirmation: passwordConfirmation,
  });
};

export default {
  login,
  register,
  sendEmailResetPassword,
  resetPassword,
  inviteCustomer,
  inviteUser,
  resendUserInvitation,
  changePassword,
};
