import React from "react";
import { useSelector } from "react-redux";
import api from "../api";

export default ({ children }) => {
  if (typeof window === "undefined") return null;

  const isAdmin = useSelector((state) => state.user.data.isAdmin);

  const refreshFunction = () => {
    if (localStorage.getItem("token")) {
      setInterval(() => {
        const refreshEndpoint = isAdmin ? api.adminRefresh : api.refresh;
        refreshEndpoint().then(({ data }) => {
          localStorage.setItem("token", data?.token ? data?.token : "");
        });
      }, 1000 * 60 * 60);
    }
  };

  window.addEventListener("load", refreshFunction);

  return <>{children}</>;
};
