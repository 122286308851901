/* eslint-disable camelcase */
import axios from "axios";
import { formatDateUtc } from "../../utils/dateUtils";
import { getRowsFromWindowSize } from "../../utils";
import { CONSULT_STATUS, CONSULT_STATUS_REAL, CONSULT_STATUS_VIRTUAL } from "../../redux/reducers";
import config from "../../config";

const BASE_URL_API = `${config.API_URL}/api/v1`;

const getFilterFromState = (state) => {
  let mainFilter = "";
  if (CONSULT_STATUS_REAL.some((s) => s === state)) {
    mainFilter = `status=${state}`;
  } else if (CONSULT_STATUS_VIRTUAL.some((s) => s === state)) {
    // TODO: backend pide filtro de tipo in-progress, y las rutas internas
    // nuestras son inprogress
    mainFilter = state === "inprogress" ? `filter=in-progress` : `filter=${state}`;
  }

  return mainFilter;
};

export const adminLogout = async () => {
  return axios.post(`${BASE_URL_API}/logout`, {});
};

// TODO: Falta revisar index handlebars
export const adminRefresh = async () => {
  return axios.post(`${BASE_URL_API}/refresh`, {});
};

export const adminGetLoggedUser = async () => {
  return axios.get(`${BASE_URL_API}/me`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const adminUpdateUserLocale = (userId, locale) =>
  axios.post(`${BASE_URL_API}/user/${userId}/updatelocale`, {
    locale,
  });

export const adminGetUrlPreSignedAvatar = async (id, filename, fileExt) => {
  return axios.post(`${BASE_URL_API}/user/${id}/avatarurl`, {
    file_name: filename,
    file_ext: fileExt,
  });
};

export const adminAddUrlSignedAvatar = async (userId, url) => {
  return axios.patch(`${BASE_URL_API}/user/${userId}/avatar`, {
    url,
  });
};

export const adminGetUser = async ({ id }) => {
  return axios.get(`${BASE_URL_API}/user/${id}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const adminGetNotificationsUser = async ({ userId }) =>
  axios.get(`${BASE_URL_API}/user/${userId}/notifications`);

export const adminGetAllConsultations = async ({
  state = CONSULT_STATUS.IN_PROGRESS,
  userId,
  page = 1,
  pageSize = getRowsFromWindowSize(),
  search = "",
  filters = {},
  sort = {},
}) => {
  let stringFilters = "";
  if (filters.assets && filters.assets.length > 0 && !filters.assets.some((at) => at.id === "all"))
    stringFilters += `&assets=${filters.assets.map((a) => a.id).join(",")}`;

  const sortedParams = sort.name ? `&orderBy=${sort.name}&orderDir=${sort.direction}` : `&orderBy=start&orderDir=asc`;

  const mainFilter = getFilterFromState(state);
  const filter = ""; // search ? "&filter=all-bookings" : "";
  return axios.get(
    `${BASE_URL_API}/user/${userId}/bookings?${mainFilter}&page=${page}&page_size=${pageSize}${stringFilters}${sortedParams}&search=${search}${filter}`,
  );
};

export const adminPostFirstContact = async ({ space, message, start, end, paymentPlan, userId }) => {
  const bookingData = {
    brand_name: message.brandname,
    space_id: space.id,
    payment_plan: paymentPlan,
    project_name: message.projectname,
    project_details: message.description,
    space_activity_coefficient_id: message.spaceActivityCoefficient,
    user_id: userId,
    start,
  };

  bookingData.end = end;

  return axios.post(`${BASE_URL_API}/bookings/${space.asset.id}/book/`, bookingData);
};

export const adminPostFirstContactWithoutSpace = async ({ assetId, adSpaces, message, start, end, userId }) =>
  axios.post(`${BASE_URL_API}/bookings/${assetId}/book/`, {
    adspace_ids: adSpaces,
    brand_name: message.brandname,
    project_name: message.projectname,
    project_details: message.description,
    user_id: userId,
    start,
    end,
  });

export const adminGetBookingTransactions = async ({ bookingId }) =>
  axios.get(`${BASE_URL_API}/invoicetransactions/bybooking/${bookingId}`);

export const adminGetBookingInvoices = async ({ bookingId }) =>
  axios.get(`${BASE_URL_API}/invoices/bybooking/${bookingId}`);

export const adminGetUrlPreSignedInvoice = async (invoiceId, file_name, file_ext) => {
  return axios.post(`${BASE_URL_API}/invoices/${invoiceId}/proofofpaymenturl`, {
    file_name,
    file_ext,
  });
};

export const adminAddUrlSignedInvoice = async (invoiceId, url) => {
  return axios.patch(`${BASE_URL_API}/invoices/${invoiceId}/proofofpaymentupdate`, {
    url,
  });
};

export const adminSendMessage = async (bookingId, content) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/message`, {
    type: "text",
    body: content,
  });

export const adminGetBooking = async ({ bookingId }) => axios.get(`${BASE_URL_API}/bookings/${bookingId}`);

export const adminGetBookingFeed = async ({ bookingId, wholeChain = true }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/feed?wholeChain=${wholeChain}`);

export const adminGetBookingOverlap = async ({ bookingId }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/overlapped`);

export const adminGetBookingExtensionChain = ({ bookingId }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/extensionchain`);

export const adminGetBookingUnavailableDateRanges = async (bookingId) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/unavailabledateranges`);

export const adminSetBookingSeen = async ({ bookingId }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/seen`, {});

export const adminSendBookingRequest = async ({ bookingId, userId }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/request`, {
    user_id: userId,
  });

export const adminSignBooking = async ({ bookingId, url }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/sign`, {
    url,
  });

export const adminGetBookingRate = (bookingId) => axios.get(`${BASE_URL_API}/bookings/${bookingId}/rating`);

export const adminGetBookingDefaultPrice = async ({ bookingId }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/defaultprice`);

export const adminGetBookingPriceByDateRange = async ({ id, start, end }) => {
  let stringFilters = "";

  if (end) stringFilters += `&end=${formatDateUtc(end, "YYYY-MM-DD")}`;

  return axios.get(
    `${BASE_URL_API}/bookings/${id}/pricebydates?start=${formatDateUtc(start, "YYYY-MM-DD")}${stringFilters}`,
  );
};

export const adminGetBookingContract = async (bookingId) =>
  axios.get(`${BASE_URL_API}/bookingscontract/bybooking/${bookingId}`);

export const adminCancelBooking = async ({ bookingId }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/cancel`, {});
