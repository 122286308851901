import React from "react";
import { Provider } from "react-redux";
import bootstrapNumbro from "./src/bootstrap-numbro";
import bootstrapDayjs from "./src/bootstrap-dayjs";
import createStore from "./src/redux/store";
import TokenRefresher from "./src/providers/token-refresher";
import "./src/styles/global.css";

// import { useLocalization } from "gatsby-theme-i18n";

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();
  // const { locale/*, config, defaultLang*/ } = useLocalization();

  // i18n.loadLocaleData(locale, { plurals: plurals[locale] });

  // i18n.load(locale, es);
  // i18n.activate(locale);
  // dynamicLoadLocale(locale)

  bootstrapNumbro();
  bootstrapDayjs();

  return (
    <Provider store={store}>
      <TokenRefresher>{element}</TokenRefresher>
    </Provider>
  );
};
