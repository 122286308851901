import {
  GET_CONSULTS,
  GET_CONSULTS_SUCCESS,
  UPDATE_CONSULTS,
  GET_CONSULTS_ERROR,
  GET_MORE_CONSULTS_SUCCESS,
} from "../actions/consults-actions";
import { GET_BOOKING_SUCCESS } from "../actions/consultation-actions";
import { getNow } from "../../utils/dateUtils";

export const MAX_ITEMS = 10;

const INITIAL_DATA = {
  data: [],
  status: "initial",
  bookingState: "",
  currentIndex: 0,
  perPage: 0,
  hasNext: true,
  hasPrevious: false,
};

const consultsReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case UPDATE_CONSULTS:
      return {
        ...state,
        data: action.payload.data || [],
        status: "success",
      };
    case GET_CONSULTS:
      return {
        ...state,
        status: "loading",
      };
    case GET_CONSULTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data || [],
        status: "success",
        currentIndex: action.payload.data.current_page,
        perPage: action.payload.data.per_page,
        hasNext: action.payload.data.current_page < action.payload.data.last_page,
        hasPrevious: action.payload.data.current_page > 1,
        bookingState: action.payload.status,
        search: action.payload.search,
      };
    case GET_CONSULTS_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    case GET_MORE_CONSULTS_SUCCESS:
      return {
        ...state,
        data: Array.from(new Set([...state.data, ...(action.payload.data.data || [])])),
        status: "success",
        currentIndex: action.payload.data.current_page,
        hasNext: action.payload.data.current_page < action.payload.data.last_page,
        hasPrevious: action.payload.data.current_page > 1,
      };
    case GET_BOOKING_SUCCESS: {
      const { data } = action.payload;
      if (state.data.find((c) => c.id === data.id)) {
        return {
          ...state,
          data: state.data.map((c) =>
            c.id === data.id
              ? {
                  ...data,
                  user_last_seen: getNow().format(),
                }
              : c,
          ),
        };
      }
      return {
        ...state,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default consultsReducer;
